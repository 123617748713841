.ui.selection .invertedSelect {
  color: white;
  border-color: white !important;
  border-width: 1px;
}

.ui.selection.visible.dropdown>.text:not(.default) .invertedSelect {
  font-weight: 400;
  color: white !important;
  border-color: white !important;
}

.ui.active.selection.dropdown .invertedSelect {
  border-color: white !important;
  border-width: 1px;
}

.ui.selection.active.dropdown .menu .invertedSelect {
  border-color: white !important;
  border-width: 1px;
}

.ui.selection.active.dropdown .invertedSelect {
  border-color: white !important;
  border-width: 1px;
}

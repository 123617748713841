.satelliteImageBrighter {
  image-rendering: pixelated;
  filter: brightness(180%) contrast(150%);
}

.satelliteImage {
  image-rendering: auto;
}

.map-circle-marker {
  /* fill-opacity: 1.0;
  stroke-opacity: 1.0; */
  transition: stroke-width 0.5s, fill-opacity 0.75s;
}

.map-circle-marker:hover {
  stroke-width: 10;
}

/* 
.all-circle-markers:hover .circle-marker-hover {
  fill-opacity: 0.4;
  transition: fill-opacity 0.5s;
}
.all-circle-markers .circle-marker-hover:hover {
  fill-opacity: 1.0;
  transition: fill-opacity 0.5s;
} */

.ui.selection.active.dropdown {
    border-color: grey;
}

.ui.selection.active.dropdown:hover {
    border-color: grey;
}

.ui.selection.active.dropdown:hover .menu {
    border-color: grey;
}

.ui.selection.active.dropdown .menu {
    border-color: grey;
}

.ui.selection.dropdown:focus {
    border-color: grey;
}

.ui.selection.dropdown:focus .menu {
    border-color: grey;
}

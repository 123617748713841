/* General app settings */

html,
body {
  height: 100%;
  width: 100%;
  background-color: #f7f4f0;
  min-height: 100%;
  min-width: 100%;  
  /* font-size: 13px !important; */
}

/* App fonts */

p {
  font-family: "Source Sans Pro", sans-serif !important;
}

body {
  font-family: "Source Sans Pro", sans-serif !important;
}

div {
  font-family: "Source Sans Pro", sans-serif !important;
}

.ui.header {
  font-family: "Source Sans Pro", sans-serif !important;
}

p,
label,
Text {
  line-height: 140%;
}

a,
a:visited {
  color: grey;
}

h1, h2, h3, h4 {
  font-weight: 500;
}




/* Adjust font sizes */

@media screen and (max-width: 1366px) {
  html,
  body {
    font-size: 11px !important;
  }
}

@media screen and (resolution: 150dpi) {
  html,
  body {
    font-size: 11px !important;
  }
}



/* Border colors for dropdowns */

.ui.selection.dropdown {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown:hover {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown:hover .menu {
  border-color: #6B9D9F;
}

.ui.selection.active.dropdown .menu {
  border-color: #6B9D9F;
}

.ui.selection.dropdown:focus {
  border-color: #6B9D9F;
}

.ui.selection.dropdown:focus .menu {
  border-color: #6B9D9F;
}



/* Buttons */

button {
  background-color: #868D8E;
  color: white;
  text-decoration: none;
  width: auto;
  height: 32px;
}

button:hover {
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 500ms;
}

.ui.button {
  background-color: #868D8E;
  color: white;
  text-decoration: none;
  width: auto;
  height: 32px;
}

.ui.button:hover {
  background-color: #868D8E;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 500ms;
}

.ui.primary.button {
  background-color: #6B9D9F;
  color: white;
}

.ui.primary.button:hover {
  background-color: #6B9D9F;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.positive.button {
  background-color: #4C8440;
  color: white;
}

.ui.positive.button:hover {
  background-color: #4C8440;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.negative.button {
  background-color: #C36126;
  color: white;
}

.ui.negative.button:hover {
  background-color: #C36126;
  opacity: 0.75;
  transition: opacity 500ms;
  color: white;
}

.ui.button {
  height: initial;
}


/* Sliders */

.home-page-slider {
  padding: 1em;
}

.welcome-slider {
  padding: 8px;
}

.welcome-slider > div > div > div > div {
  background: white;
  padding: 24px;
  height: 100%;
}
.welcome-slider > div > div > div {
  height: 365px;
  padding: 0 4px;
}

.slick-prev:before, .slick-next:before {
  background-color: #FBBD08;
  opacity: 1.0;
  font-weight: bold;
  font-size: 25px;
  padding: 5px;
}



/* Menus */

.ui.secondary.vertical.menu .menuPrimaryItem { 
  border-radius: 0 !important;
}

.ui.secondary.menu .menuPrimaryItem:hover {
  color: rgb(0, 0, 0, 0.85) !important;
  background-color: #F8EED0 !important;
  transition: background-color 0.75s;
}

.ui.secondary.menu .menuPrimaryItem:hover .menuPrimaryItemIcon {
  filter: brightness(0) invert(35%) !important;
}

.ui.secondary.menu .menuSecondaryItem:hover {
  background-color: rgba(234, 204, 116, 0.4) !important;
  transition: background-color 0.75s;
}

.opacityHover {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.25s;
}

.opacityHover:hover {
  opacity: 1;
}

.opacityInvertedHover {
  cursor: pointer;
  opacity: 1.0;
  transition: opacity 0.25s;
}

.opacityInvertedHover:hover {
  opacity: 0.7;
}

/* Input fields */
/* .ui.input.focus>input .ui.input>input:focus */
/* .input-no-border  {
  border-left: hidden;
  border-right: hidden;
  border-top: hidden;
  outline: none;
  border-color: #6B9D9F
} */

.ui.input>input:focus {
  border-color: #6B9D9F
}

/* Progress bars */

.ui.green.progress .bar {
  background-color: #4C8440;
}

.ui.yellow.progress .bar {
  background-color: #F0BD38;
}

.ui.red.progress .bar {
  background-color: #C36126;
}

.iconButton:hover {
  cursor: pointer;
  color: #F0BD38;
  transition: color 0.25s;
}

.textLink {
  cursor: pointer;
  color: #6B9D9F;
  font-size: "95%";
  font-weight: 600;
  display: inline;
}

.textLink:hover {
  color: #9CB5B6;
  transition: color 0.25s;
}



/* Menu hovers */

.fieldCardOnHover:hover {
  background: #F8EED0 !important;
  transition: background-color 0.75s;
}

.fieldCardOnHover:hover .fieldCartoon {
  background: white !important;
  transition: background-color 0.75s;
}

.fieldLayerCardOnHover:hover {
  background: #F8EED0 !important;
  transition: background-color 0.75s;
}

.fieldLayerCardOnHover:hover .fieldCartoon {
  background: white !important;
  transition: background-color 0.75s;
}

.fieldCardOnHoverGreyBackground:hover {
  background: #f0f0f0 !important;
  transition: background-color 0.75s;
}

.backgroundColorOnHover:hover {
  background: rgba(240, 189, 56, 0.75) !important;
  transition: background-color 0.75s;
}

.brightBackgroundColorOnHover:hover {
  color: black;
  background: rgba(240, 189, 56, 0.25) !important;
  transition: background-color 0.75s;
}

.hoverOpacity:hover {
  opacity: 0.75;
  transition: opacity 0.75s;
}



/* Calendars */

.flatpickr-calendar {
  filter: none !important;
  -webkit-filter: none !important;
}

.calendarNewDate .ui.label {
  background: #89A275 !important;
  color: #89A275 !important;
}



/* Toasters */

.Toastify__toast--success {
  background: #4C8440;
  color: white,
}
.Toastify__toast--warning {
  background: #F0BD38;
  color: white,
}
.Toastify__toast--error {
  background: #C36126;
  color: white,
}
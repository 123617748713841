.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background-color: #6B9D9F;
    color: white;
    font-size: 125%;
    font-weight: bold;
}

input[type="file"] {
    display: none;
}
